#scroll-view {
  bottom: 0;
  /* leave room for 100px header */
  height: 100%;
  width: 100%;
  overflow: auto;
  flex: 3;
  background-color: var(--pdf-background);
  height: 550px;
  z-index: 0;
}

#viewer {
  margin: auto;
}
